import React from "react";
import { Container, Row, Col } from "reactstrap";
import pilotoProsperia from "data/pilotoProsperiaGDLV.json";
import pilotoLinkCampanas from "data/pilotoLinkCampanas.json";
import guardianesSHCP from "data/guardianesSHCP.json";
import optometriaGDLV from "data/optometriaGDLV.json";
import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

import styles from "./BienvenidoStyles.module.css";

export default ({
  comenzar,
  urlParams,
  handleChange,
  handleChangeOpto,
  handleCodigoOpto,
  handleEsfuerzo,
  nameEsfuerzo,
  esfuerzo,
  handleCodigoCampana,
  comenzarConFlujo,
  region,
  busquedaCampaign,
  busquedaCampaignOpto,
  busquedaCampaignGeneral,
  creaCampaign,
  creaCampaignOpto,
  name,
  nameOpto,
  nameCampana,
  campaign,
  renderButtons,
  consultaCodigo,
  nombreGuardian,
  selecCampaign,
  returnBusqueda,
  returnBusquedaOpto,
  idCampaign,
}) => {
  const sucursalesPermitidas = ["LaViga", "Iztapalapa", "Coacalco", "Ecatepec", "AragonNeza", "Toluca"];
  const renderTipoBienvenidaButtons = (tipoBienvenida) => {
    switch (tipoBienvenida) {
      case "Metro":
        return (
          <Container fluid className={styles.containerButtons}>
            <Row className={`${styles.rowContainerButtonsGDLV}`}>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={3}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Consulta Adulto Metro")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8.9}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONSULTA GENERAL
                      </Col>
                      <Col xs={3} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={3}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Vision Integral Metro")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        VISION INTEGRAL
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={3}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Consulta Retina Metro")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONSULTA RETINA
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
            </Row>
            <Row className={`${styles.rowContainerButtonsGDLV}`}>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={4}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Consulta Glaucoma Metro")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONSULTA GLAUCOMA
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={4}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Consulta Cornea Metro")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={7}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONSULTA CORNEA
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={4}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() =>
                    comenzarConFlujo("Consulta Oculoplastia Metro")
                  }
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONSUL. OCULOPLASTIA
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
            </Row>
             <Row className={`${styles.rowContainerButtonsGDLV}`}>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={4}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Consulta oftalmopediatria")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONSULTA OFTALMOPEDIATRIA
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
              </Row>

          </Container>
        );
        break;

      /* ------------------------------------------   Campañas    ----------------------------------------------- */

      case "GeneralCampanas":
        if (renderButtons) {
          //Componente
          return (
            <Container fluid className={styles.containerButtons}>
              <Row style={{ width: "100%" }}>
                <Col
                  lg={4}
                  xs={12}
                  className={`${styles.colContainerCampaign}`}
                >
                  <p className={`${styles.tituloCampaign}`}>
                    Código de Campaña:
                  </p>
                  <p className={`${styles.subTitulo_Campaign}`}>{campaign}</p>
                </Col>
                <Col
                  lg={4}
                  xs={12}
                  className={`${styles.colContainerCampaign}`}
                >
                  <p className={`${styles.tituloCampaign}`}>Guardián: </p>
                  <p className={`${styles.subTitulo_Campaign}`}>
                    {nombreGuardian}
                  </p>
                </Col>

                <Col
                  lg={4}
                  xs={12}
                  className={`${styles.colContainerCampaign}`}
                >
                  <p className={`${styles.tituloCampaign}`}>
                    Opto. responsable:{" "}
                  </p>
                  <p className={`${styles.subTitulo_Campaign}`}>
                    {nombreGuardian}
                  </p>
                </Col>
              </Row>
              <Row className={`${styles.rowContainerButtonsGDLV}`}>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() => comenzarConFlujo("Agenda General")}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          CREAR TAMIZAJE
                        </Col>
                        <Col
                          xs={4}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
              </Row>
              <Row className={`${styles.rowContainerRegresar}`}>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerRegresar__GDLV}`}
                    onClick={() => returnBusquedaOpto()}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={10}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          REGRESAR
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
              </Row>
            </Container>
          );
        } else {
          return (
            <Row style={{ width: "100%" }}>
              <Col
                lg={4}
                xs={12}
                className={`${styles.colContainerListGuardianes}`}
              >
                <p className={`${styles.colContainerTitle__GDLV}`}>
                  Campaña uno
                  <input
                    className={`${styles.inputNombre__GDLV}`}
                    type="text"
                    name="nombre"
                    placeholder="Ingrese código de campaña"
                    value={nameEsfuerzo}
                    //value={nameOpto}
                    onChange={(e) => {
                      //handleCodigoOpto(e);

                      handleEsfuerzo(e);
                    }}
                  />{" "}
                </p>
                {/* <select onChange={(e) => handleChange(e)}>
                {pilotoProsperia.map((doctor) => (
                  <option value={doctor.campañaGuardian}>
                    {doctor.nombreGuardian}
                  </option>
                ))}
              </select> */}
              </Col>
              <Col lg={4} xs={12} className={`${styles.colContainerListOpto}`}>
                <h5>Optometrista responsable</h5>
                <select onChange={(e) => handleChangeOpto(e)}>
                  {optometriaGDLV.map((region) => (
                    <option value={region.nombreRegion}>
                      {region.nombreRegion}
                    </option>
                  ))}
                </select>
              </Col>
              <Col
                lg={4}
                xs={12}
                className={`${styles.colContainerListGuardianes}`}
              >
                <button
                  className={`${styles.colContainerButton__OPTO}`}
                  onClick={() =>
                    //busquedaCampaignOpto(nameOpto, region)
                    busquedaCampaignGeneral(nameEsfuerzo, region)
                  }
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        BUSCAR
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
              {consultaCodigo === "" ? (
                <Row style={{ width: "100%" }}>
                  <Col
                    lg={12}
                    xs={12}
                    className={`${styles.colContainerListGuardianes3}`}
                  >
                    <p className={`${styles.etiquetaNoExiste}`}>
                      Esperando datos de campaña...
                    </p>
                  </Col>
                </Row>
              ) : (
                <Row style={{ width: "100%" }}>
                  <Col
                    lg={12}
                    xs={12}
                    className={`${styles.colContainerListGuardianes3}`}
                  >
                    {consultaCodigo !== "No Existen campañas" ? (
                      <p className={`${styles.colMensaje_GDLV}`}>
                        {consultaCodigo == "Campaña no encontrada" ? (
                          <div className={`${styles.etiquetaNoExisteSub2}`}>
                            Campaña encontrada con exito!
                            <p className={`${styles.etiquetaSubTitulo__GDLV}`}>
                              {nombreGuardian}
                            </p>
                            <button
                              className={`${styles.colContainerButton__GDLV2}`}
                              onClick={() => creaCampaignOpto()}
                              type="button"
                            >
                              <Container fluid>
                                <Row>
                                  <Col
                                    xs={8}
                                    className={`${styles.colContainerNombreBoton}`}
                                  >
                                    CONTINUAR
                                  </Col>
                                </Row>
                              </Container>
                            </button>
                          </div>
                        ) : (
                          <div className={`${styles.etiquetaTitulo__GDLV3}`}>
                            <div
                              className={`${styles.etiquetaTitulo__GDLVOPTO}`}
                            >
                              {" "}
                              Codigo de la Campaña:
                              <p
                                className={`${styles.etiquetaSubTitulo__GDLV}`}
                              >
                                {campaign}
                              </p>
                            </div>
                            <div
                              className={`${styles.etiquetaTitulo__GDLVOPTO}`}
                            >
                              Nombre del guardián:
                              <p
                                className={`${styles.etiquetaSubTitulo__GDLV}`}
                              >
                                {nombreGuardian}
                              </p>
                            </div>
                            <div
                              className={`${styles.etiquetaTitulo__GDLVOPTO}`}
                            >
                              Esfuerzo:
                              <p
                                className={`${styles.etiquetaSubTitulo__GDLV}`}
                              >
                                {esfuerzo}
                              </p>
                            </div>
                            <button
                              className={`${styles.colContainerButton__GDLV}`}
                              onClick={() => selecCampaign()}
                              type="button"
                            >
                              <Container fluid>
                                <Row>
                                  <Col
                                    xs={8}
                                    className={`${styles.colContainerNombreBoton}`}
                                  >
                                    CONTINUAR
                                  </Col>
                                </Row>
                              </Container>
                            </button>
                          </div>
                        )}
                      </p>
                    ) : (
                      <div className={`${styles.etiquetaNoExiste2}`}>
                        ¡Este guardián no existe!
                        <p className={`${styles.etiquetaNoExisteSub}`}>
                          Favor de comunicarse con los responsables de Salauno
                        </p>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Row>
          );
        }

        break;

      /* ------------------------------------------       ----------------------------------------------- */

      case "Azucar":
        return (
          <Row className={`${styles.rowContainerButtonsGDLV}`}>
            <Col className={`${styles.colContainerButtonGDLV}`} lg={12} xs={12}>
              {" "}
              <button
                className={`${styles.colContainerButton__button}`}
                onClick={() => comenzarConFlujo("Retina clinicas Azucar")}
                type="button"
              >
                <Container fluid>
                  <Row>
                    <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                      CONSULTA RETINA
                    </Col>
                    <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                      <span>
                        <svg className={`${styles.svg}`}>
                          <use xlinkHref={`${sprite}#icon-arrow-right`} />
                        </svg>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </button>
            </Col>
            <Row className={`${styles.rowContainerButtonsGDLV}`}>
            {/* {urlParams.sucursal === "LaViga" || urlParams.sucursal === "Iztapalapa" || urlParams.sucursal === "Coacalco" ?( */}
            {sucursalesPermitidas.includes(urlParams.sucursal) ?(
              <Col className={`${styles.colContainerButtonGDLV}`} lg={12} xs={12}>
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("General clinicas Azucar")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                        CONSULTA ADULTO
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
            ): null}
            </Row>
            
            {/* <Col className={`${styles.colContainerButtonGDLV}`} lg={6} xs={12}>
              {" "}
              <button
                className={`${styles.colContainerButton__button}`}
                onClick={() =>
                  comenzarConFlujo("Consulta Adulto Clinicas Azucar")
                }
                type="button"
              >
                <Container fluid>
                  <Row>
                    <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                      CONSULTA GENERAL
                    </Col>
                    <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                      <span>
                        <svg className={`${styles.svg}`}>
                          <use xlinkHref={`${sprite}#icon-arrow-right`} />
                        </svg>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </button>
            </Col> */}
          </Row>
        );
        break;
        case "CLIVI":
        return (
          <Row className={`${styles.rowContainerButtonsGDLV}`}>
            <Col className={`${styles.colContainerButtonGDLV}`} lg={12} xs={12}>
              {" "}
              <button
                className={`${styles.colContainerButton__button}`}
                onClick={() => comenzarConFlujo("Consulta de Retina CLIVI")}
                type="button"
              >
                <Container fluid>
                  <Row>
                    <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                      CONSULTA RETINA
                    </Col>
                    <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                      <span>
                        <svg className={`${styles.svg}`}>
                          <use xlinkHref={`${sprite}#icon-arrow-right`} />
                        </svg>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </button>
            </Col>

            {/* Espacio entre los botones para CLIVI */}
            <Col lg={12} xs={12} className={styles.spacingCol}><br></br></Col>

            
            {<Col className={`${styles.colContainerButtonGDLV}`} lg={6} xs={12}>
              {" "}
              <button
                className={`${styles.colContainerButton__button}`}
                onClick={() =>
                  comenzarConFlujo("Consulta Adulto CLIVI")
                }
                type="button"
              >
                <Container fluid>
                  <Row>
                    <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                      CONSULTA GENERAL
                    </Col>
                    <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                      <span>
                        <svg className={`${styles.svg}`}>
                          <use xlinkHref={`${sprite}#icon-arrow-right`} />
                        </svg>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </button>
            </Col> }
          </Row>
        );
        break;
        case "N-SOYMAS":
        return (
          <Row className={`${styles.rowContainerButtonsGDLV}`}>
            {/* Espacio entre los botones para N-SOYMAS */}
            <Col lg={12} xs={12} className={styles.spacingCol}><br></br></Col>

            
            {<Col className={`${styles.colContainerButtonGDLV}`} lg={6} xs={12}>
              {" "}
              <button
                className={`${styles.colContainerButton__button}`}
                onClick={() =>
                  comenzarConFlujo("Consulta Adulto SOYMAS")
                }
                type="button"
              >
                <Container fluid>
                  <Row>
                    <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                      CONSULTA GENERAL
                    </Col>
                    <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                      <span>
                        <svg className={`${styles.svg}`}>
                          <use xlinkHref={`${sprite}#icon-arrow-right`} />
                        </svg>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </button>
            </Col> }
            {/* <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={6}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Consulta Glaucoma SOYMAS")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONSULTA GLAUCOMA
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>*/}
          </Row>
          
        );
        break;
        case "JNH":
          return (
            <Row className={`${styles.rowContainerButtonsGDLV}`}>
              <Col className={`${styles.colContainerButtonGDLV}`} lg={12} xs={12}>
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Consulta de Retina JNH")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                        CONSULTA RETINA
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
  
              {/* Espacio entre los botones para JNH*/}
              <Col lg={12} xs={12} className={styles.spacingCol}><br></br></Col>
  
              
              {<Col className={`${styles.colContainerButtonGDLV}`} lg={6} xs={12}>
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() =>
                    comenzarConFlujo("Consulta Adulto JNH")
                  }
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                        CONSULTA GENERAL
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col> }
              {/* <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={6}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() => comenzarConFlujo("Consulta Glaucoma SOYMAS")}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={8}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          CONSULTA GLAUCOMA
                        </Col>
                        <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>*/}
            </Row>
            
          );
          break;
      case "SHCP":
        return (
          <Container fluid className={styles.containerButtons}>
            <Row className={`${styles.rowContainerButtonsGDLV}`}>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={4}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Guardianes de la vista")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={7}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONSULTA GENERAL
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
              <Col
                className={`${styles.colContainerButtonGDLV}`}
                lg={4}
                xs={12}
              >
                {" "}
                <button
                  className={`${styles.colContainerButton__button}`}
                  onClick={() => comenzarConFlujo("Vision Integral GDLV")}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={7}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        VISION INTEGRAL
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span>
                          <svg className={`${styles.svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
            </Row>
          </Container>
        );
        break;
      case "GDLV-P":
        if (renderButtons) {
          return (
            <Container fluid className={styles.containerButtons}>
              <Row style={{ width: "100%" }}>
                <Col
                  lg={6}
                  xs={12}
                  className={`${styles.colContainerCampaign}`}
                >
                  <p className={`${styles.tituloCampaign}`}>
                    Código de Campaña:
                  </p>
                  <p className={`${styles.subTitulo_Campaign}`}>{campaign}</p>
                </Col>
                <Col
                  lg={6}
                  xs={12}
                  className={`${styles.colContainerCampaign}`}
                >
                  <p className={`${styles.tituloCampaign}`}>Campaña: </p>
                  <p className={`${styles.subTitulo_Campaign}`}>
                    {nombreGuardian}
                  </p>
                </Col>
              </Row>
              <Row className={`${styles.rowContainerButtonsGDLV}`}>
                {/* <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() =>
                      comenzarConFlujo("Guardianes de la vista Retina")
                    }
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          CONSULTA RETINA
                        </Col>
                        <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col> */}
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() => comenzarConFlujo("Guardianes de la vista")}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          CONSULTA GENERAL
                        </Col>
                        <Col
                          xs={4}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() => comenzarConFlujo("Vision Integral GDLV")}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          VISION INTEGRAL
                        </Col>
                        <Col
                          xs={4}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() =>
                      comenzarConFlujo("Guardianes de la vista candidato")
                    }
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          CREAR CANDIDATO
                        </Col>
                        <Col
                          xs={4}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
              </Row>
              {/* <Row className={`${styles.rowContainerButtonsGDLV}`}>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() => comenzarConFlujo("Vision Integral GDLV")}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          VISION INTEGRAL
                        </Col>
                        <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
              </Row> */}
              <Row className={`${styles.rowContainerRegresar}`}>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerRegresar__GDLV}`}
                    onClick={() => returnBusqueda()}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={10}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          REGRESAR
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
              </Row>
            </Container>
          );
        } else {
          return (
            <Row style={{ width: "100%" }}>
              <Col
                lg={6}
                xs={12}
                className={`${styles.colContainerListGuardianes}`}
              >
                <p className={`${styles.colContainerTitle__GDLV}`}>
                  Código de campaña
                  <input
                    className={`${styles.inputNombre__GDLV}`}
                    type="text"
                    name="nombre"
                    placeholder="Ingrese el código de campaña"
                    value={name}
                    onChange={(e) => {
                      busquedaCampaign(e);
                    }}
                  />{" "}
                </p>
                {/* <select onChange={(e) => handleChange(e)}>
                {pilotoProsperia.map((doctor) => (
                  <option value={doctor.campañaGuardian}>
                    {doctor.nombreGuardian}
                  </option>
                ))}
              </select> */}
              </Col>
              {consultaCodigo === "" ? (
                <Col
                  lg={6}
                  xs={12}
                  className={`${styles.colContainerListGuardianes2}`}
                >
                  <p className={`${styles.etiquetaNoExiste}`}>
                    Esperando datos de la campaña...
                  </p>
                </Col>
              ) : (
                <Col
                  lg={6}
                  xs={12}
                  className={`${styles.colContainerListGuardianes2}`}
                >
                  {consultaCodigo !== "No Existen campañas" ? (
                    <p className={`${styles.colMensaje_GDLV}`}>
                      {consultaCodigo == "Campaña encontrada" ? (
                        <div className={`${styles.etiquetaTitulo__GDLV3}`}>
                          <div className={`${styles.etiquetaTitulo__GDLV}`}>
                            {" "}
                            Codigo de la Campaña:
                            <p className={`${styles.etiquetaSubTitulo__GDLV2}`}>
                              {campaign}
                            </p>
                          </div>
                          <div className={`${styles.etiquetaTitulo__GDLV2}`}>
                            Campaña:
                            <p className={`${styles.etiquetaSubTitulo__GDLV3}`}>
                              {nombreGuardian}
                            </p>
                          </div>
                          <button
                            className={`${styles.colContainerButton__GDLV}`}
                            onClick={() => selecCampaign()}
                            type="button"
                          >
                            <Container fluid>
                              <Row>
                                <Col
                                  xs={8}
                                  className={`${styles.colContainerNombreBoton}`}
                                >
                                  CONTINUAR
                                </Col>
                              </Row>
                            </Container>
                          </button>
                        </div>
                      ) : (
                        <div className={`${styles.etiquetaNoExisteSub}`}>
                          ¡Campaña no encontrada!
                          <p className={`${styles.etiquetaSubTitulo__GDLV}`}>
                            {nombreGuardian}
                          </p>
                        </div>
                      )}
                    </p>
                  ) : (
                    <div className={`${styles.etiquetaNoExiste2}`}>
                      ¡Esta campaña no existe!
                      <p className={`${styles.etiquetaNoExisteSub}`}>
                        Favor de comunicarse con los responsables de Salauno
                      </p>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          );
        }
        break;

      case "GDLV-Optometria":
        if (renderButtons) {
          return (
            <Container fluid className={styles.containerButtons}>
              <Row style={{ width: "100%" }}>
                <Col
                  lg={6}
                  xs={12}
                  className={`${styles.colContainerCampaign}`}
                >
                  <p className={`${styles.tituloCampaign}`}>
                    Código de Campaña:
                  </p>
                  <p className={`${styles.subTitulo_Campaign}`}>{campaign}</p>
                </Col>
                <Col
                  lg={6}
                  xs={12}
                  className={`${styles.colContainerCampaign}`}
                >
                  <p className={`${styles.tituloCampaign}`}>Guardián: </p>
                  <p className={`${styles.subTitulo_Campaign}`}>
                    {nombreGuardian}
                  </p>
                </Col>
              </Row>
              <Row className={`${styles.rowContainerButtonsGDLV}`}>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() => comenzarConFlujo("Vision Integral GDLV")}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          VISION INTEGRAL
                        </Col>
                        <Col
                          xs={4}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() => comenzarConFlujo("Guardianes de la vista")}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          CONSULTA GENERAL
                        </Col>
                        <Col
                          xs={4}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() =>
                      comenzarConFlujo("Guardianes de la vista tamizaje")
                    }
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          CREAR TAMIZAJE
                        </Col>
                        <Col
                          xs={4}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
              </Row>
              <Row className={`${styles.rowContainerRegresar}`}>
                <Col
                  className={`${styles.colContainerButtonGDLV}`}
                  lg={4}
                  xs={12}
                >
                  {" "}
                  <button
                    className={`${styles.colContainerRegresar__GDLV}`}
                    onClick={() => returnBusquedaOpto()}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={10}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          REGRESAR
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
              </Row>
            </Container>
          );
        } else {
          return (
            <Row style={{ width: "100%" }}>
              <Col
                lg={4}
                xs={12}
                className={`${styles.colContainerListGuardianes}`}
              >
                <p className={`${styles.colContainerTitle__GDLV}`}>
                  Código del guardián
                  <input
                    className={`${styles.inputNombre__GDLV}`}
                    type="text"
                    name="nombre"
                    placeholder="Ingrese código de guardian"
                    value={nameOpto}
                    onChange={(e) => {
                      handleCodigoOpto(e);
                    }}
                  />{" "}
                </p>
                {/* <select onChange={(e) => handleChange(e)}>
                {pilotoProsperia.map((doctor) => (
                  <option value={doctor.campañaGuardian}>
                    {doctor.nombreGuardian}
                  </option>
                ))}
              </select> */}
              </Col>
              <Col lg={4} xs={12} className={`${styles.colContainerListOpto}`}>
                <h5>Optometrista responsable</h5>
                <select onChange={(e) => handleChangeOpto(e)}>
                  {optometriaGDLV.map((region) => (
                    <option value={region.nombreRegion}>
                      {region.nombreRegion}
                    </option>
                  ))}
                </select>
              </Col>
              <Col
                lg={4}
                xs={12}
                className={`${styles.colContainerListGuardianes}`}
              >
                <button
                  className={`${styles.colContainerButton__OPTO}`}
                  onClick={() => busquedaCampaignOpto(nameOpto, region)}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        BUSCAR
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
              {consultaCodigo === "" ? (
                <Row style={{ width: "100%" }}>
                  <Col
                    lg={12}
                    xs={12}
                    className={`${styles.colContainerListGuardianes3}`}
                  >
                    <p className={`${styles.etiquetaNoExiste}`}>
                      Esperando datos del guardian y/o región...
                    </p>
                  </Col>
                </Row>
              ) : (
                <Row style={{ width: "100%" }}>
                  <Col
                    lg={12}
                    xs={12}
                    className={`${styles.colContainerListGuardianes3}`}
                    fluid
                  >
                    {consultaCodigo !== "No Existen campañas" ? (
                      <p className={`${styles.colMensaje_GDLV}`}>
                        {consultaCodigo == "Campaña no encontrada" ? (
                          <div className={`${styles.etiquetaNoExisteSub2}`}>
                            ¡Guardián encontrado con exito!
                            <p className={`${styles.etiquetaSubTitulo__GDLV}`}>
                              {nombreGuardian}
                            </p>
                            <button
                              className={`${styles.colContainerButton__GDLV2}`}
                              onClick={() => creaCampaignOpto()}
                              type="button"
                            >
                              <Container fluid>
                                <Row>
                                  <Col
                                    xs={8}
                                    className={`${styles.colContainerNombreBoton}`}
                                  >
                                    CONTINUAR
                                  </Col>
                                </Row>
                              </Container>
                            </button>
                          </div>
                        ) : (
                          <div className={`${styles.etiquetaTitulo__GDLV3}`}>
                            <div
                              className={`${styles.etiquetaTitulo__GDLVOPTO}`}
                            >
                              {" "}
                              Codigo de la Campaña:
                              <p
                                className={`${styles.etiquetaSubTitulo__GDLV}`}
                              >
                                {campaign}
                              </p>
                            </div>
                            <div
                              className={`${styles.etiquetaTitulo__GDLVOPTO}`}
                            >
                              Nombre del guardián:
                              <p
                                className={`${styles.etiquetaSubTitulo__GDLV}`}
                              >
                                {nombreGuardian}
                              </p>
                            </div>
                            <button
                              className={`${styles.colContainerButton__GDLV}`}
                              onClick={() => selecCampaign()}
                              type="button"
                            >
                              <Container fluid>
                                <Row>
                                  <Col
                                    xs={8}
                                    className={`${styles.colContainerNombreBoton}`}
                                  >
                                    CONTINUAR
                                  </Col>
                                </Row>
                              </Container>
                            </button>
                          </div>
                        )}
                      </p>
                    ) : (
                      <div className={`${styles.etiquetaNoExiste2}`}>
                        ¡Este guardián no existe!
                        <p className={`${styles.etiquetaNoExisteSub}`}>
                          Favor de comunicarse con los responsables de Salauno
                        </p>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Row>
          );
        }
        break;
      case "Campanas":
        return (
          <Row className={`${styles.rowContainerButtonsGDLV}`}>
            <Col className={`${styles.colContainerButtonGDLV}`} xs={12}>
              {" "}
              <button
                className={`${styles.colContainerButton__button}`}
                onClick={() => comenzarConFlujo("Consulta Adulto")}
                type="button"
              >
                <Container fluid>
                  <Row>
                    <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                      AGENDAR CITA
                    </Col>
                    <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                      <span>
                        <svg className={`${styles.svg}`}>
                          <use xlinkHref={`${sprite}#icon-arrow-right`} />
                        </svg>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </button>
            </Col>
          </Row>
        );
        break;
      default:
        return (
          <button
            className={`${styles.colContainerButton__button}`}
            onClick={comenzar}
            type="button"
          >
            <Container fluid>
              <Row>
                <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                  ENTRAR
                </Col>
                <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                  <span>
                    <svg className={`${styles.svg}`}>
                      <use xlinkHref={`${sprite}#icon-arrow-right`} />
                    </svg>
                  </span>
                </Col>
              </Row>
            </Container>
          </button>
        );
        break;
    }
  };

  const renderPickListTipoBienvenida = (tipoBienvenida) => {
    switch (tipoBienvenida) {
      case "Campanas":
        return (
          <Col xs={12} className={`${styles.colContainerListGuardianes}`}>
            <h5>Nombre del guardian</h5>
            <select onChange={(e) => handleChange(e)}>
              {pilotoLinkCampanas.map((campana) => (
                <option value={campana.codigoCampana}>
                  {campana.nombreCampana}
                </option>
              ))}
            </select>
          </Col>
        );
        break;
      case "SHCP":
        return (
          <Col xs={12} className={`${styles.colContainerListGuardianes}`}>
            <h5>Nombre de usuario </h5>
            <select onChange={(e) => handleChange(e)}>
              {guardianesSHCP.map((campana) => (
                <option value={campana.campañaGuardian}>
                  {campana.nombreGuardian}
                </option>
              ))}
            </select>
          </Col>
        );
        break;
      default:
        return null;
        break;
    }
  };

  return (
    <Container fluid className={`${styles.containerBienvenido}`}>
      <Container className={`${styles.bienvenidoContainer}`}>
        <Row className={`${styles.rowContainer}`}>
          <Col xs={12} className={`${styles.colContantainer}`}>
            <Row className={`${styles.rowContainerBienvenido}`}>
              <Col xs={12} className={`${styles.colContainerTitleSubTitle}`}>
                <Row className={`${styles.rowContainerTitleSubtitle}`}>
                  <Col xs={12} className={`${styles.colContainerTitle}`}>
                    <p className={`${styles.colContainerTitle__title}`}>
                      Bienvenido a salauno
                    </p>
                  </Col>
                  <Col xs={12} className={`${styles.colContainerSubTitle}`}>
                    <p className={`${styles.colContainerSubTitle__subTitle}`}>
                      ¡Agenda una cita!
                    </p>
                  </Col>
                  {renderPickListTipoBienvenida(urlParams.medio)}
                  <Col xs={12} className={`${styles.colContainerButton}`}>
                    {renderTipoBienvenidaButtons(urlParams.medio)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
